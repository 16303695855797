<template>
  <form @submit.prevent="login">
    <div class="input-group">
      <input
        type="email"
        class="form-control"
        placeholder="Enter your email to continue"
        v-model="userStore.email"
        name="username"
        autocomplete="username"
      />
      <i class="off-txt"></i>
    </div>
    <div class="input-group">
      <div class="checkbox-group">
        <input type="checkbox" id="check" v-model="compatibleConfirm" />
        <label for="check">
          <span>
            I agree with
            <span
              class="button-link"
              @click.prevent="appStore.showPopup({ component: PopupPrivacy })"
            >
              privacy policy
            </span>
          </span>
        </label>
      </div>
    </div>
    <div class="input-group mb-0">
      <button
        class="btn btn-b btn-danger d-block"
        type="submit"
        :disabled="!compatibleConfirm || !emailIsValid || loginLoading"
      >
        Continue
        <MainLoader v-if="loginLoading" />
      </button>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { computed, ComputedRef, Ref, ref, defineEmits } from "vue";
import { useUserStore } from "@/stores/user";
import { checkEmailIsValid } from "@/helpers";
import MainLoader from "@/components/MainLoader.vue";
import { useAppStore } from "@/stores/app";
import PopupPrivacy from "@/components/popups/PopupPrivacy.vue";

const userStore = useUserStore();
const appStore = useAppStore();

const emit = defineEmits(["code-sent"]);
const loginLoading: Ref<boolean> = ref(false);
const compatibleConfirm: Ref<boolean> = ref(false);
const emailIsValid: ComputedRef<boolean> = computed(() =>
  checkEmailIsValid(userStore.email)
);
async function login(): Promise<void> {
  loginLoading.value = true;
  await userStore.login();
  emit("code-sent");
  loginLoading.value = false;
}
</script>
