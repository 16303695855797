import { defineStore } from "pinia";
import { computed, ComputedRef, ref, Ref, watch } from "vue";
import query, { ErrorType, QueryMethod } from "@/query";
import router from "@/router";
import { useEsimStore } from "@/stores/esim";
import { useDataStore } from "@/stores/data";
import Socket from "@/socket";

export const TOKEN_LOCAL_KEY = "toosim_web_token";

export class User {
  readonly id: number;
  readonly email: string;
  readonly cashback: number;
  readonly promoSubscribed: boolean;

  constructor(
    id: number,
    email: string,
    cashback: number,
    promoSubscribed: boolean
  ) {
    this.id = id;
    this.email = email;
    this.cashback = cashback;
    this.promoSubscribed = promoSubscribed;
  }
}

function userFromJson(json: Record<any, any>): User {
  return new User(
    parseInt(json["id"]) || 0,
    json["email"].toString(),
    parseInt(json["cashback"]) || 0,
    json.promoSubscribed === true
  );
}

export const useUserStore = defineStore("user", () => {
  const email: Ref<string> = ref("");
  const id: Ref<number | null> = ref(null);
  const cashback: Ref<number> = ref(0);
  const isAuth: ComputedRef<boolean> = computed(() => !!id.value);
  const promoSubscribed: Ref<boolean> = ref(false);

  async function auth(): Promise<User | ErrorType | null | any> {
    const response = await query({
      path: "auth",
      method: QueryMethod.POST,
    });

    return getAuthResponse(response);
  }

  async function login(sendMailAttemptNumber: number): Promise<boolean> {
    const response = await query({
      path: "auth/login",
      method: QueryMethod.POST,
      params: {
        email: email.value,
        sendMailAttemptNumber,
      },
    });
    if (!response || typeof response.userRegistered !== "boolean") {
      throw new Error();
    }

    return response.userRegistered;
  }

  async function validate(
    code: string
  ): Promise<User | ErrorType | null | any> {
    const response = await query({
      path: "auth/validate",
      method: QueryMethod.POST,
      params: {
        email: email.value,
        code: code,
      },
    });

    return getAuthResponse(response);
  }

  function getAuthResponse(response: any): User | ErrorType | null | any {
    if (response && response.user) {
      const user: User = userFromJson(response.user);
      localStorage.setItem(TOKEN_LOCAL_KEY, response.token.toString());
      id.value = user.id;
      email.value = user.email;
      cashback.value = user.cashback;
      promoSubscribed.value = user.promoSubscribed;

      Socket.connect();

      return user;
    } else {
      return response;
    }
  }

  function logout(): void {
    const esimStore = useEsimStore();

    esimStore.clearAll(undefined);
    id.value = 0;
    email.value = "";
    localStorage.removeItem(TOKEN_LOCAL_KEY);
    router.push("/#auth");
  }

  async function deleteAccount(): Promise<void> {
    await query({
      path: "user/delete_account",
      method: QueryMethod.DELETE,
    });
    logout();
  }

  async function subscribePromo(subscribed: boolean): Promise<void> {
    await query({
      path: "promo/set_subscription",
      method: QueryMethod.POST,
      params: { subscribed },
    });
  }

  watch(promoSubscribed, () => {
    subscribePromo(promoSubscribed.value);
  });

  return {
    id,
    isAuth,
    email,
    cashback,
    promoSubscribed,
    auth,
    login,
    validate,
    logout,
    deleteAccount,
  };
});
