import { defineStore } from "pinia";
import { computed, ComputedRef, Ref, ref } from "vue";
import query, { QueryMethod } from "@/query";
import { useUserStore } from "@/stores/user";
import { useEsimStore } from "@/stores/esim";

export class Country {
  id: number;
  name: string;
  iso: string;
  alias: string;
  isRegion: boolean;

  constructor(
    id: number,
    name: string,
    iso: string,
    alias: string,
    isRegion: boolean
  ) {
    this.id = id;
    this.name = name;
    this.iso = iso;
    this.alias = alias;
    this.isRegion = isRegion;
  }
}

export function countryFromJson(json: Record<any, any>): Country {
  return new Country(
    parseInt(json.id) || 0,
    json.name.toString(),
    json.iso.toString(),
    json.alias.toString(),
    json.isRegion === true
  );
}

export class FaqItem {
  order: number;
  answer: string;
  question: string;
  opened: boolean;

  constructor(order: number, answer: string, question: string) {
    this.order = order;
    this.answer = answer;
    this.question = question;
    this.opened = false;
  }
}

export class DeviceItem {
  name: string;
  models: string[];

  constructor(name: string, models: string[]) {
    this.name = name;
    this.models = models;
  }
}

export class ActivationInstructionItem {
  videoguide_link: string;
  tutorial_html_text: string;

  constructor(videoLink: string, tutorialHtml: string) {
    this.videoguide_link = videoLink;
    this.tutorial_html_text = tutorialHtml;
  }
}

function activationInstructionItemFromJson(
  json: Record<any, any>
): ActivationInstructionItem {
  return new ActivationInstructionItem(
    String(json.videoguide_link),
    String(json.tutorial_html_text)
  );
}

export class ActivationInstructionDevices {
  warningText: string;
  ios: ActivationInstructionItem;
  android: ActivationInstructionItem;

  constructor(
    warningText: string,
    ios: ActivationInstructionItem,
    android: ActivationInstructionItem
  ) {
    this.warningText = warningText;
    this.ios = ios;
    this.android = android;
  }
}

function deviceItemFromJson(json: Record<any, any>): DeviceItem {
  const models: string[] = [];

  const jsonModels = json.models;
  if (Array.isArray(jsonModels)) {
    jsonModels.forEach((jsonModel): void => {
      if (jsonModel && jsonModel.name) {
        models.push(jsonModel.name.toString());
      }
    });
  }

  return new DeviceItem(json.name.toString(), models);
}

function faqItemFromJson(json: Record<any, any>): FaqItem {
  return new FaqItem(
    parseInt(json.order) || 0,
    json.answer.toString(),
    json.question.toString()
  );
}
export const useDataStore = defineStore("data", () => {
  const countries: Ref<Country[]> = ref([]);
  const faqItems: Ref<FaqItem[]> = ref([]);
  const supportedDevices: Ref<DeviceItem[]> = ref([]);
  const privacyText: Ref<string> = ref("");
  const termsText: Ref<string> = ref("");
  const activationInstructions: Ref<ActivationInstructionDevices | null> =
    ref(null);
  const minTransactionPriceWithCashbackPercents: Ref<number> = ref(100);
  const cashbackMaxPercent: Ref<number> = ref(0);
  const referFriendReward: Ref<number> = ref(0);
  const cashbackEnabled: Ref<boolean> = ref(false);
  const cashbackInfo: Ref<Record<any, any>> = ref({});
  const whatsappLink: Ref<string | undefined> = ref();
  const minPaymentTermsText: Ref<string | undefined> = ref();
  const promoTermsTitle: Ref<string | undefined> = ref();
  const promoTermsText: Ref<string | undefined> = ref();
  const maxSendMailAttemptNumber: Ref<number> = ref(1);

  const userStore = useUserStore();

  const showCashback: ComputedRef<boolean> = computed(() => {
    return (
      userStore.cashback > 0 &&
      minTransactionPriceWithCashbackPercents.value < 100 &&
      cashbackEnabled.value
    );
  });

  async function getCountries(): Promise<Country[]> {
    const response = await query({
      path: "country/get_all",
      method: QueryMethod.GET,
    });

    const resCountries: Country[] = [];

    if (Array.isArray(response)) {
      response.forEach((jsonCountry) => {
        const country: Country = countryFromJson(jsonCountry);
        resCountries.push(country);
        countries.value.push(country);
      });
    }

    return resCountries;
  }

  async function getData(): Promise<void> {
    const response = await query({
      path: "auth/data",
      method: QueryMethod.GET,
    });

    if (response) {
      // Add FAQ
      const faqJson = response.faq;
      if (Array.isArray(faqJson)) {
        faqJson.forEach((faq): void => {
          faqItems.value.push(faqItemFromJson(faq));
        });
      }

      // Add supported devices
      const devicesJson = response.supportedDevices;
      if (Array.isArray(devicesJson)) {
        devicesJson.forEach((device) => {
          supportedDevices.value.push(deviceItemFromJson(device));
        });
      }

      // Add privacy policy text
      const privacyJson = response.privacy;
      if (Array.isArray(privacyJson) && privacyJson.length) {
        const privacyFirstJson = privacyJson[0];
        if (privacyFirstJson) {
          privacyText.value = privacyFirstJson.text.toString();
        }
      }

      // Add esim terms text
      const esimTerms = response.esimTerms;
      if (Array.isArray(esimTerms) && esimTerms.length) {
        const esimTermsFirstJson = esimTerms[0];
        if (esimTermsFirstJson) {
          termsText.value = esimTermsFirstJson.text.toString();
        }
      }

      const activationInstruction = response.activationInstruction;
      if (activationInstruction) {
        const warningText = String(activationInstruction.warning_text);
        const ios = activationInstruction.ios_instruction;
        const android = activationInstruction.android_instruction;
        activationInstructions.value = new ActivationInstructionDevices(
          warningText,
          activationInstructionItemFromJson(ios),
          activationInstructionItemFromJson(android)
        );
      }

      // Add cashback variables
      if (response.cashbackMinPaymentPercent) {
        minTransactionPriceWithCashbackPercents.value =
          response.cashbackMinPaymentPercent * 100;
      }

      if (response.cashbackMaxPercent) {
        cashbackMaxPercent.value = response.cashbackMaxPercent || 0;
      }

      if (response.referFriendReward) {
        referFriendReward.value = response.referFriendReward || 0;
      }

      if (response.rewardsTerms) {
        if (response.rewardsTerms.min_payment_terms) {
          minPaymentTermsText.value =
            response.rewardsTerms.min_payment_terms.text;
        }

        if (response.rewardsTerms.general_terms)
          cashbackInfo.value = {
            title: response.rewardsTerms.general_terms.title,
            text: response.rewardsTerms.general_terms.text,
          };
      }

      if (response.promoSubscriptionTerms) {
        if (response.promoSubscriptionTerms.title) {
          promoTermsTitle.value = response.promoSubscriptionTerms.title;
        }

        if (response.promoSubscriptionTerms.text) {
          promoTermsText.value = response.promoSubscriptionTerms.text;
        }
      }

      if (typeof response.whatsapplink === "string") {
        whatsappLink.value = response.whatsapplink;
      }

      if (
        typeof response.maxSendMailAttemptNumber === "number" &&
        response.maxSendMailAttemptNumber > 0
      ) {
        maxSendMailAttemptNumber.value = response.maxSendMailAttemptNumber;
      }

      cashbackEnabled.value = response.cashbackEnabled === true;
    }
  }

  async function sendSupport(
    firstName: string,
    email: string,
    question: string
  ): Promise<boolean> {
    const response = await query({
      path: "mail/support",
      method: QueryMethod.POST,
      params: {
        firstName,
        email,
        question,
      },
    });

    return response && response.success == true;
  }

  return {
    countries,
    minTransactionPriceWithCashbackPercents,
    cashbackMaxPercent,
    referFriendReward,
    cashbackEnabled,
    faqItems,
    supportedDevices,
    privacyText,
    termsText,
    activationInstructions,
    cashbackInfo,
    whatsappLink,
    minPaymentTermsText,
    promoTermsTitle,
    promoTermsText,
    showCashback,
    maxSendMailAttemptNumber,
    getCountries,
    getData,
    sendSupport,
  };
});
