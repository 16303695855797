<template>
  <div class="middle">
    <div class="group-list-sim-card pb-5">
      <div class="container">
        <div class="sim-card-compartment">
          <h5>My eSims</h5>
          <div class="button-toggle-group mb-4">
            <button
              class="button-toggle"
              :class="{ active: esimType === EsimType.active }"
              @click="esimType = EsimType.active"
              :disabled="esimsLoading"
            >
              Active
            </button>
            <button
              class="button-toggle"
              :class="{ active: esimType === EsimType.archive }"
              @click="esimType = EsimType.archive"
              :disabled="esimsLoading"
            >
              Archive
            </button>
          </div>
          <EsimCard
            v-for="esim in esimStore.esimsByType(esimType)"
            :key="esim.id"
            :esim="esim"
          />
          <MainLoader
            v-if="esimsLoading && !esimStore.esimsByType(esimType).length"
            primary
          />
          <p v-else-if="!esimStore.esimsByType(esimType).length">
            You have no eSIM eSIM will be shown here after purchase
          </p>
          <button
            v-if="!esimStore.esimsByType(esimType).length"
            class="btn btn-b btn-danger profile__buy"
            @click="goSearch"
          >
            <i class="icon app-d"></i>Buy an eSIM
          </button>
        </div>
        <div
          class="description-tarif-content-item"
          :class="{ active: managementOpened }"
          style="border: none; padding: 0"
        >
          <h6 @click="managementOpened = !managementOpened">
            <b> Account management </b>
          </h6>
          <div class="tarif-content">
            <p class="profile__label">Delete my account</p>
            <div class="profile-row-col">
              <button
                class="btn btn-b btn-default profile__btn"
                :class="{ pressed: deletePressed }"
                @click="onDeleteClick"
                @mouseleave="deletePressed = false"
              >
                {{ deletePressed ? "Click again" : "Delete account" }}
              </button>
              <p class="profile__btn--label">
                Your account will be permanently deleted
              </p>
            </div>
            <br />
            <p class="profile__label">Promotional materials</p>
            <div class="checkbox-group">
              <input
                v-model="userStore.promoSubscribed"
                type="checkbox"
                id="check"
              />
              <label for="check">
                <span>
                  Inform me about new promotions and tips.
                  <button
                    class="button-link"
                    @click.prevent="
                      appStore.showPopup({ component: PopupPromoTerms })
                    "
                  >
                    Read more
                  </button>
                </span>
              </label>
            </div>
          </div>
        </div>
        <button class="btn btn-b btn-default mt-5" @click="userStore.logout">
          Log out
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import EsimCard from "@/components/esim/EsimCard.vue";
import { EsimType, useEsimStore } from "@/stores/esim";
import { onMounted, Ref, ref, watch } from "vue";
import { useUserStore } from "@/stores/user";
import MainLoader from "@/components/MainLoader.vue";
import { useAppStore } from "@/stores/app";
import PopupDeleteAccount from "@/components/popups/PopupDeleteAccount.vue";
import router from "@/router";
import { useBundleStore } from "@/stores/bundle";
import { copyToClipBoard } from "@/helpers";
import PopupDevices from "@/components/popups/PopupDevices.vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";
import PopupPromoTerms from "@/components/popups/PopupPromoTerms.vue";

const esimStore = useEsimStore();
const userStore = useUserStore();
const appStore = useAppStore();
const bundleStore = useBundleStore();

const esimsLoading: Ref<boolean> = ref(false);
const esimType: Ref<EsimType> = ref(EsimType.active);
const deletePressed: Ref<boolean> = ref(false);
const managementOpened: Ref<boolean> = ref(false);

async function getEsims() {
  esimsLoading.value = true;
  await esimStore.getEsims(esimType.value, true);
  esimsLoading.value = false;
}

function onDeleteClick() {
  if (deletePressed.value) {
    appStore.showPopup({ component: PopupDeleteAccount });
    deletePressed.value = false;
  } else {
    deletePressed.value = true;
  }
}

const goSearch = (): void => {
  bundleStore.clearBundles();
  router.push("/");
};

onMounted(getEsims);
watch(esimType, getEsims);
</script>

<style scoped lang="sass">
.profile
  &__label
    padding: 0

  &__buy
    margin-top: 10px
    margin-bottom: 50px

    @media (max-width: 700px)
      width: 100%

      i
        display: none

  &__title
    font-size: 18px
    font-weight: 700

  &-row
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 10px

    &-col
      display: flex
      flex-direction: column
      gap: 10px
      align-items: flex-start
      width: 100%

  &__btn
    min-width: 200px
    max-width: 100%
    transition: 0.3s

    &.pressed
      background-color: red
      color: #ffffff

      &:hover
        background-color: red
        color: #ffffff

    &--label
      font-size: 14px
      opacity: 0.5
      padding: 0
      text-align: right

@media (max-width: 700px)
  .btn
    width: 100%
</style>
